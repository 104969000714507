import { MessageSquare as MessageSquareIcon } from "@tamagui/lucide-icons";

import { Circle, LoadingButton, Subheadline5, YStack } from "@medbillai/ui";

import {
  EmptyLoadingWidget,
  EmptyWidgetWrapper,
} from "../general-widget/EmptyWidgetWrapper";

export const EmptyCaseList = ({
  handlePress,
  loading,
}: {
  handlePress: () => void;
  loading: boolean;
}) => {
  return (
    <>
      {loading ? (
        <EmptyLoadingWidget />
      ) : (
        <EmptyWidgetWrapper>
          <Circle
            size="$5"
            backgroundColor="$groupedSecondary"
            zIndex={2}
            justifyContent="center"
            alignItems="center"
            mb="$3.5"
          >
            <MessageSquareIcon
              size={24}
              strokeWidth={2}
              color="$textSecondary"
            />
          </Circle>
          <Subheadline5 textAlign="center" maxWidth={300}>
            Your cases will appear here when you have them.
          </Subheadline5>
          <YStack height="$4" justifyContent="center" alignItems="center">
            <LoadingButton
              onPress={handlePress}
              size="$3"
              width="$12"
              height="$3"
              mt="$5"
            >
              Start a case
            </LoadingButton>
          </YStack>
        </EmptyWidgetWrapper>
      )}
    </>
  );
};
