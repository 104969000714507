import { useCallback } from "react";
import { useRouter } from "solito/router";

import type { CheckInsuranceLinksQuery } from "@medbillai/graphql-types";

import { YStack, useMedia } from "@medbillai/ui";

import { insuranceLinkPlanTypesUrl } from "@medbillai/utils/urls";

import { BillListItem } from "../../../bill/components/BillListItem";
import { BillListItemHome } from "../../../bill/components/BillListItemHome";
import { BillsOverviewBanner } from "../../../bill/components/BillsOverviewBanner";
import type { NormalizedEob } from "../../../bill/utils/types";
import { WidgetHeader } from "../general-widget/WidgetHeader";
import { WidgetWrapper } from "../general-widget/WidgetWrapper";
import { EmptyBillsList } from "./EmptyBillsList";

export const BillsWidget = ({
  data,
  links,
  loading,
  flaggedBillsCount = 0,
}: {
  data: NormalizedEob[];
  links: CheckInsuranceLinksQuery | undefined;
  loading: boolean;
  flaggedBillsCount?: number;
}) => {
  const { push } = useRouter();
  const eobs = data.slice(0, 5) || [];
  const isEmpty = eobs.length === 0;
  const hasInsuranceLinks =
    (links?.me?.flexpaLinks?.length ?? 0) +
      (links?.me?.webAgentLinks?.length ?? 0) >
    0;

  const handlePress = useCallback(() => {
    push(insuranceLinkPlanTypesUrl());
  }, [push]);

  const handleBannerPress = useCallback(() => {
    push("/bills/flagged");
  }, [push]);

  return (
    <WidgetWrapper
      showGradient={isEmpty && !loading && !hasInsuranceLinks}
      isEmpty={isEmpty}
    >
      <WidgetHeader label="Recent Bills" />
      {flaggedBillsCount > 0 && (
        <BillsOverviewBanner
          issueOrBillCount={flaggedBillsCount}
          onPress={handleBannerPress}
        />
      )}
      {isEmpty ? (
        <EmptyBillsList
          loading={loading}
          hasInsuranceLinks={hasInsuranceLinks}
          handlePress={handlePress}
        />
      ) : (
        <BillList eobs={eobs} />
      )}
    </WidgetWrapper>
  );
};

const BillList = ({ eobs }: { eobs: NormalizedEob[] }) => {
  const { gtMd } = useMedia();
  const BillListItemComponent = gtMd ? BillListItemHome : BillListItem;
  return (
    <YStack
      animation="bouncyTight"
      enterStyle={{
        opacity: 0,
        y: 10,
        scale: 0.9,
      }}
      exitStyle={{
        opacity: 0,
        y: -10,
        scale: 0.9,
      }}
    >
      {eobs.map((eob, index) => (
        <BillListItemComponent
          key={eob.id}
          eob={eob}
          borderHide={index == 0}
          webHomePage={true}
        />
      ))}
    </YStack>
  );
};
