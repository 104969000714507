import { type ReactNode, useState } from "react";

import { YStack, useMedia } from "@medbillai/ui";

import { BackgroundRadialGrantedGradient } from "../../../../components/BackgroundRadialGrantedGradient";

export const WidgetWrapper = ({
  children,
  showGradient = false,
  isEmpty,
}: {
  children: ReactNode[];
  showGradient?: boolean;
  isEmpty?: boolean;
}) => {
  const { sm } = useMedia();
  const [contentHeight, setContentHeight] = useState(300);

  const contentHeightSet = (height: number) => {
    setContentHeight(height);
  };

  return (
    <YStack
      paddingHorizontal="$4"
      $platform-web={{
        backgroundColor: isEmpty ? "transparent" : "$background",
        borderRadius: "$10",
        shadowColor: "$shadowColor",
        shadowOffset: { height: 4, width: 0 },
        shadowRadius: 8,
        shadowOpacity: 0.08,
        borderWidth: 0.5,
        borderColor: "$strokeSecondary",
        overflow: "hidden",
        ...(sm && {
          paddingHorizontal: "$0",
        }),
      }}
      animation="100ms"
      animateOnly={["height"]}
      height={contentHeight}
      overflow="hidden"
      backgroundColor="green"
    >
      <BackgroundRadialGrantedGradient
        opacity={showGradient ? 1 : 0}
        animation="200ms"
        height="200%"
        top={-300}
      />
      <YStack
        onLayout={e => contentHeightSet(e.nativeEvent.layout.height)}
        paddingVertical="$4"
        paddingTop="$6"
      >
        {children}
      </YStack>
    </YStack>
  );
};
