import { useCallback } from "react";
import { useRouter } from "solito/router";

import { IssueType, type QueryIssuesV2Query } from "@medbillai/graphql-types";

import { YStack } from "@medbillai/ui";

import { Flag } from "@medbillai/utils/flag";
import {
  caseIntakeFlowUrl,
  homeUrl,
  issuePreviewUrl,
} from "@medbillai/utils/urls";

import { useBoolVariation } from "../../../../lib/flag";
import { CaseListItem } from "../../../cases/components/CaseListItem";
import type { CaseListProps } from "../../../cases/utils/types";
import { WidgetHeader } from "../general-widget/WidgetHeader";
import { WidgetWrapper } from "../general-widget/WidgetWrapper";
import { EmptyCaseList } from "./EmptyCaseList";

export const CasesWidget = ({
  loading,
  data,
}: {
  loading: boolean;
  data: QueryIssuesV2Query | undefined;
}) => {
  const { push } = useRouter();

  const issues = data?.queryIssuesV2?.slice(0, 5) || [];
  const isEmpty = issues.length === 0;

  const isCaseIntakeV1Enabled = useBoolVariation(Flag.CASE_INTAKE_V1, false);

  const handlePress = useCallback(() => {
    if (isCaseIntakeV1Enabled) {
      push(caseIntakeFlowUrl(homeUrl()));
    } else {
      push(issuePreviewUrl(IssueType.ONBOARDING));
    }
  }, [isCaseIntakeV1Enabled, push]);

  return (
    <WidgetWrapper isEmpty={isEmpty}>
      <WidgetHeader label="Open Cases" onPress={handlePress} />
      {isEmpty ? (
        <EmptyCaseList handlePress={handlePress} loading={loading} />
      ) : (
        <CaseList issues={issues} />
      )}
    </WidgetWrapper>
  );
};

const CaseList = ({ issues }: CaseListProps) => {
  return (
    <YStack
      animation="bouncyTight"
      enterStyle={{
        opacity: 0,
        y: 10,
        scale: 0.9,
      }}
      exitStyle={{
        opacity: 0,
        y: -10,
        scale: 0.9,
      }}
    >
      {issues.map((issue, index) => (
        <CaseListItem
          key={issue.id}
          issue={issue}
          borderHide={index == 0}
          webHomePage={true}
        />
      ))}
    </YStack>
  );
};
