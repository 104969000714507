import { gql } from "@medbillai/graphql-types";

export const checkLinksQuery = gql(/* GraphQL */ `
  query CheckInsuranceLinks {
    me {
      id
      flexpaLinks {
        id
        active
      }
      webAgentLinks {
        __typename
        id
        active
      }
    }
  }
`);
