import { useQuery } from "@apollo/client";
import { memo } from "react";

import { ScreenView, type ScreenViewProps } from "../../components/ScreenView";
import { errorHandling } from "../../lib/apollo/utils";
import { useFlaggedEobsCount } from "../bill/utils/useFlaggedEobs";
import { useNormalizedEobs } from "../bill/utils/useNormalizedEobs";
import { allFilter } from "../bill/utils/utils";
import { getIssuesQueryV2 } from "../cases/utils/queries";
import { filterMap, filterOptionIds } from "../cases/utils/utils";
import { MessageWidget } from "./components/MessageWidget";
import { BillsWidget } from "./components/bills-widget/BillsWidget";
import { CasesWidget } from "./components/cases-widget/CasesWidget";
import { checkLinksQuery } from "./utils/queries";

const BillsWidgetMemo = memo(BillsWidget);
const MessageWidgetMemo = memo(MessageWidget);
const CasesWidgetMemo = memo(CasesWidget);

export type HomeScreenProps = {
  screenViewProps?: Omit<ScreenViewProps, "children">;
};

export function HomeScreen({ screenViewProps }: HomeScreenProps) {
  const { loading: linksLoading, data: linksData } = useQuery(checkLinksQuery, {
    context: errorHandling("caller"),
  });
  const {
    loading: billsLoading,
    data: billsData,
    refetch: refetchBills,
  } = useNormalizedEobs(allFilter);
  const {
    loading: issuesLoading,
    data: issuesData,
    refetch: refetchIssues,
  } = useQuery(getIssuesQueryV2, {
    context: errorHandling("caller"),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    refetchWritePolicy: "merge",
    variables: filterMap[filterOptionIds.open],
  });

  const {
    loading: flaggedBillsLoading,
    count: flaggedBillsCount,
    refetch: refetchFlaggedCount,
  } = useFlaggedEobsCount();

  const handleRefresh = async () => {
    await Promise.all([refetchBills(), refetchIssues(), refetchFlaggedCount()]);
  };

  return (
    <ScreenView
      noPadding
      backgroundColor="$backgroundSecondary"
      onRefresh={handleRefresh}
      centerHorizontal
      widthLimit={1000}
      stackProps={{
        "$platform-web": {
          gap: "$10",
          marginBottom: "$10",
          paddingHorizontal: "$4",
        },
      }}
      {...screenViewProps}
    >
      <MessageWidgetMemo />
      <BillsWidgetMemo
        loading={billsLoading || linksLoading || flaggedBillsLoading}
        data={billsData}
        links={linksData}
        flaggedBillsCount={flaggedBillsCount || 0}
      />
      <CasesWidgetMemo loading={issuesLoading} data={issuesData} />
    </ScreenView>
  );
}
