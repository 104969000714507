import { useRouter } from "solito/router";

import {
  DateText,
  Paragraph,
  Paragraph2,
  XStack,
  YStack,
  styled,
} from "@medbillai/ui";

import { billUrl } from "@medbillai/utils";

import { SingularEvents, singularService } from "../../../lib/singular";
import { type NormalizedEob } from "../utils/types";
import { formatCostTotal } from "../utils/utils";
import { BillListItemIcon } from "./shared/BillListItemIcon";

type BillListItemProps = {
  eob: NormalizedEob;
  borderHide?: boolean;
  flashListView?: boolean;
  webHomePage?: boolean;
};

const SubTitleParagraph = styled(Paragraph2, {
  theme: "alt1",
  whiteSpace: "nowrap",
  lineHeight: 16,
});

const TitleParagraph = styled(Paragraph, {
  overflow: "hidden",
  numberOfLines: 1,
});

export const BillListItemHome = ({
  eob,
  borderHide = false,
  flashListView = false,
}: BillListItemProps) => {
  const { push } = useRouter();
  const openBill = () => {
    singularService.event(SingularEvents.openBill);
    push(
      billUrl(
        eob.resourceId,
        eob.location || "Unknown Location",
        eob.decision || "",
      ),
    );
  };

  const mainTitle = eob.title ? eob.title : eob.location ?? "-";
  const subTitle = mainTitle !== eob.location ? eob.location : "No Description";

  return (
    <YStack
      px="$4"
      onPress={openBill}
      cur="pointer"
      pressStyle={{
        backgroundColor: "$backgroundPress",
      }}
      hoverStyle={{ backgroundColor: "$backgroundPress" }}
      br="$4"
    >
      <XStack
        ai="center"
        jc="flex-start"
        gap="$4"
        py="$4"
        flex={1}
        borderTopWidth={borderHide ? 0 : 0.5}
        borderBottomWidth={flashListView ? 0.5 : 0}
        borderColor="$strokeSecondary"
        backgroundColor="transparent"
      >
        <BillListItemIcon status={eob.decision} />
        <TitleParagraph overflow="hidden" textOverflow="hidden" f={1} bold>
          {mainTitle}
        </TitleParagraph>
        {subTitle && (
          <XStack f={1} alignItems="center" jc="flex-end" gap="$4">
            <SubTitleParagraph
              numberOfLines={1}
              overflow="hidden"
              textOverflow="hidden"
            >
              {subTitle}
            </SubTitleParagraph>
          </XStack>
        )}
        <XStack width="$13">
          <DateText
            date={eob.serviceDate || ""}
            element={props => (
              <SubTitleParagraph {...props} f={1} textAlign="right" />
            )}
            format="MMMM Do, YYYY"
            utc={true}
          />
        </XStack>
        <XStack width="$6">
          <TitleParagraph f={1} textAlign="right">
            {formatCostTotal(eob.totalCost, "$0")}
          </TitleParagraph>
        </XStack>
      </XStack>
    </YStack>
  );
};
