import { useLink } from "solito/link";

import {
  CalendarText,
  Paragraph,
  Paragraph2,
  Pill,
  XStack,
  YStack,
  styled,
} from "@medbillai/ui";

import { issueUrl } from "@medbillai/utils/urls";

import type { CaseProps } from "../utils/types";
import {
  cleanMarkdownText,
  formatEvent,
  getChatPillStyle,
  getIssueStatus,
  getTextColor,
} from "../utils/utils";

const DateTextStyle = styled(Paragraph2, {
  whiteSpace: "nowrap",
  textAlign: "left",
});

export const CaseListItem = ({
  issue,
  borderHide = false,
  flashListView = false,
  selected,
  webHomePage = false,
}: CaseProps) => {
  const status = getIssueStatus({
    closedAt: issue.closedAt,
    firstUnseenEventId: issue.firstUnseenEvent?.id,
  });
  const unread = status == "NEW_ACTIVITY";

  const lastEvent = issue.lastEvent;

  // First check if firstUnseenEvent is available,
  // if available display that event formatted,
  // otherwise display most recent event formatted
  const eventContent = formatEvent(issue.firstUnseenEvent || lastEvent);

  return (
    <YStack
      {...useLink({ href: issueUrl(issue.id, unread) })}
      px="$4"
      cur="pointer"
      pressStyle={{
        backgroundColor: "$backgroundPress",
      }}
      $platform-native={{
        f: 1,
      }}
      backgroundColor={selected ? "$backgroundPress" : "transparent"}
      hoverStyle={{ backgroundColor: "$backgroundPress" }}
      br={webHomePage ? "$4" : "$0"}
    >
      <YStack
        ai="center"
        jc="flex-start"
        gap="$2"
        py="$4"
        flex={1}
        borderTopWidth={borderHide ? 0 : 0.5}
        borderBottomWidth={flashListView ? 0.5 : 0}
        borderColor="$strokeSecondary"
        backgroundColor="transparent"
      >
        <XStack gap="$5" ai="center" jc="space-between" f={1} width="100%">
          <Paragraph f={1} numberOfLines={1} bold={unread}>
            {issue.title}
          </Paragraph>
          <Pill {...getChatPillStyle(status)} />
        </XStack>
        <XStack gap="$5" ai="center" jc="space-between" f={1} width="100%">
          <Paragraph2
            f={1}
            numberOfLines={1}
            overflow="hidden"
            textOverflow="ellipses"
            bold={unread}
            color={getTextColor(status)}
            // For some reason, the text overflow is not working on web, so we need to set the style manually
            style={{
              textOverflow: "ellipsis",
            }}
          >
            {cleanMarkdownText(eventContent)}
          </Paragraph2>
          <CalendarText
            date={lastEvent?.sentAt || ""}
            element={props => (
              <DateTextStyle
                {...props}
                color={getTextColor(status)}
                bold={unread}
              />
            )}
          />
        </XStack>
      </YStack>
    </YStack>
  );
};
