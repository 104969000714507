import Head from "next/head";

import { HomeLayout } from "@medbillai/app/screens/home/layout.web";
import { HomeScreen } from "@medbillai/app/screens/home/screen";

import { AuthGate } from "../utils/AuthGate";
import { type NextPageWithLayout } from "./_app";

export const Page: NextPageWithLayout = () => {
  return (
    <>
      <Head>
        <title>Home</title>
      </Head>
      <HomeScreen />
    </>
  );
};

Page.getLayout = page => (
  <AuthGate>
    <HomeLayout fullPage backgroundColor="$backgroundSecondary">
      {page}
    </HomeLayout>
  </AuthGate>
);

export default Page;
