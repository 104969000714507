import { XStack, YStack, type YStackProps, styled } from "tamagui";

const commonStyles: YStackProps = {
  backgroundColor: "$color1",
  borderColor: "$borderColor",
  borderWidth: "$0.5",
  p: "$3",
  gap: "$3",
  $sm: { width: "100%" },
};

export const YBlock = styled(YStack, {
  // the type isn't inferred correctly for border-radius
  br: "$4",
  ...commonStyles,
  variants: {
    stretch: {
      true: {
        $gtSm: { maxWidth: "100%", width: "100%" },
      },
    },
  },
});

export const XBlock = styled(XStack, {
  // the type isn't inferred correctly for border-radius
  br: "$4",
  ...commonStyles,
  variants: {
    stretch: {
      true: {
        $gtSm: { maxWidth: "100%", width: "100%" },
      },
    },
  },
});
