import { type ReactNode, type Ref, useState } from "react";
import {
  RefreshControl,
  type ScrollView as ScrollViewNative,
  useWindowDimensions,
} from "react-native";

import {
  ScrollView,
  type ScrollViewProps,
  XStack,
  YStack,
  isWeb,
  styled,
} from "@medbillai/ui";

import { BackgroundRadialGrantedGradient } from "./BackgroundRadialGrantedGradient";
import { ShadowLoginBanner } from "./ShadowLoginBanner";

// This is a standardized component for wrapping screens in the
// application, so that we can have a consistent look and feel
// across the app.

const ScreenStack = styled(YStack, {
  name: "ScreenStack",

  p: "$3",
  gap: "$3",
  alignItems: "stretch",

  variants: {
    centerHorizontal: {
      true: {
        alignSelf: "center",
      },
    },
    centerVertical: {
      true: {
        justifyContent: "center",
      },
    },
    centerContent: {
      true: {
        alignItems: "center",
      },
    },
    extraPadding: {
      true: {
        p: "$6",
        $gtSm: { p: "$8" },
      },
    },
    noPadding: {
      true: {
        p: 0,
        $gtSm: { p: 0 },
      },
    },
  } as const,
});

type ScrollViewContainerStyle = Exclude<
  ScrollViewProps["contentContainerStyle"],
  "unset" | undefined
>;

const scrollViewContainerStyle = (): ScrollViewContainerStyle => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  flexGrow: 1,
});

export type ScreenViewProps = {
  children: ReactNode;
  disableScroll?: boolean;
  // More whitespace.
  extraPadding?: boolean;
  // Less whitespace.
  noPadding?: boolean;
  // Center the screen vertically and/or horizontally.
  centerVertical?: boolean;
  centerHorizontal?: boolean;
  // Center the content of the screen horizontally.
  centerContent?: boolean;
  // Cap the width of the screen (web only).
  widthLimit?: boolean | number;
  // Optional background color.
  backgroundColor?: ScrollViewProps["backgroundColor"];
  // Allow deep customization if needed.
  scrollViewProps?: ScrollViewProps & { ref?: Ref<ScrollViewNative> };
  scrollViewContainerProps?: ScrollViewContainerStyle;
  stackProps?: Record<string, unknown>;
  // Additional content before or after the stack.
  renderBefore?: ReactNode;
  renderAfter?: ReactNode;
  // If paddingTop is set on the scrollViewContainerProps, this
  // can be used to offset the refresh control, ideally matching
  // the paddingTop value
  contentRefreshOffset?: number;
  // If provided, the screen will have a gradient applied to the top of the
  // screen, usually used with transparent headers and standard padding
  // applied to counteract the lack of header spacing
  backgroundRadialGradient?: boolean;
  // If provided, the screen will have a pull-to-refresh action.
  onRefresh?: () => Promise<unknown>;
};
const defaultWidthLimit = 600;

export const ScreenView = ({
  children,
  disableScroll = false,
  extraPadding = false,
  noPadding = false,
  centerVertical = false,
  centerHorizontal = false,
  centerContent = false,
  widthLimit = true,
  backgroundColor,
  scrollViewProps,
  scrollViewContainerProps,
  stackProps,
  renderBefore,
  renderAfter,
  contentRefreshOffset = 0,
  backgroundRadialGradient = false,
  onRefresh,
}: ScreenViewProps) => {
  const { height, fontScale } = useWindowDimensions();
  // Default to scroll on larger screens to the parent prop. However on small
  // screens there are alot of scenarios the simulator dehaves differently than
  // the device, so we default to scroll on smaller screens to prevent blocking
  // users from accessing features.
  // We also force scroll on smaller screens if the font scale is larger than 1.1
  // because we know there is accesibility settings enabled on the application
  // and cannot anticipate what the screen will render as.
  const forceScrollEnabled = height <= 800 || fontScale > 1.1;
  const [refreshing, setRefreshing] = useState(false);
  const doRefresh = async () => {
    if (onRefresh) {
      setRefreshing(true);
      await onRefresh();
      setRefreshing(false);
    }
  };
  let maxWidth: string | number | undefined = undefined;
  if (widthLimit) {
    if (typeof widthLimit === "number") {
      maxWidth = widthLimit;
    } else if (isWeb && widthLimit === true) {
      maxWidth = defaultWidthLimit;
    }
  }

  return (
    <>
      {backgroundRadialGradient && <BackgroundRadialGrantedGradient />}
      <ScrollView
        keyboardShouldPersistTaps="handled"
        // Remove disable scroll on smaller screens to allow for scrolling, this
        // is to make sure everything is accesible on smaller screens, that cant
        // be replicated in the simulator
        scrollEnabled={forceScrollEnabled ? true : !disableScroll}
        centerContent={centerVertical}
        contentContainerStyle={{
          ...scrollViewContainerStyle(),
          ...scrollViewContainerProps,
        }}
        backgroundColor={backgroundColor}
        width="100%"
        fg={1}
        refreshControl={
          onRefresh && (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={doRefresh}
              progressViewOffset={contentRefreshOffset}
            />
          )
        }
        {...scrollViewProps}
      >
        {!isWeb && (
          <YStack fg={0}>
            <ShadowLoginBanner />
          </YStack>
        )}
        {renderBefore ?? null}
        <ScreenStack
          centerHorizontal={centerHorizontal}
          centerVertical={centerVertical}
          centerContent={centerContent}
          extraPadding={extraPadding}
          noPadding={noPadding}
          width="100%"
          fg={1}
          maxWidth={maxWidth}
          backgroundColor={backgroundColor}
          {...stackProps}
        >
          {children}
        </ScreenStack>
        {renderAfter ? (
          <ScreenStack
            maxWidth={maxWidth}
            width="100%"
            centerHorizontal={centerHorizontal}
            extraPadding={extraPadding}
            noPadding={noPadding}
            py="$0"
          >
            {renderAfter}
          </ScreenStack>
        ) : null}
      </ScrollView>
    </>
  );
};

// Solo variants are for full-screen content that gets centered.
export const ScreenViewCenter = (props: ScreenViewProps) => {
  return <ScreenView centerVertical {...props} />;
};

export const ScreenViewTopThird = (props: ScreenViewProps) => {
  return (
    <ScreenView
      renderBefore={<XStack flexGrow={1} flexShrink={1} maxHeight={100} />}
      stackProps={{ flexGrow: 4, flexShrink: 0 }}
      {...props}
    />
  );
};

// Sometimes we use this on screens that are not in a stack which therefore
// wont have a header. We will use a standard value of 100 accross the board
// in that because because the header size is ~98, but if we use the header
// hook it breaks on screens outside of a navigator like id check. For screens
// that we use the background but not shadow circle 50 is a safter number
// because those screens tend to extend in length
export const gradientBackgroundCircleScreenPadding = 100;
export const gradientBackgroundInfoScreenPadding =
  gradientBackgroundCircleScreenPadding / 2;

export const ScreenViewShadowCircle = (props: ScreenViewProps) => {
  return (
    <ScreenView
      backgroundRadialGradient
      centerHorizontal
      extraPadding
      disableScroll
      renderBefore={<XStack minHeight="10%" $short={{ minHeight: "2%" }} />}
      stackProps={{
        px: "$8",
        gap: "$6",
        $short: { gap: "$2", px: "$4" },
        pt: gradientBackgroundCircleScreenPadding,
      }}
      {...props}
    />
  );
};

export const FlowTextFormScreenView = (props: ScreenViewProps) => {
  return (
    <ScreenView
      disableScroll
      noPadding
      centerHorizontal
      stackProps={{
        flex: 1,
        paddingTop: "$2",
        paddingHorizontal: "$4.5",
      }}
      {...props}
    />
  );
};

export const FlowScreenView = (props: ScreenViewProps) => {
  return (
    <ScreenView
      disableScroll
      noPadding
      stackProps={{
        flex: 1,
        paddingTop: "$2",
        paddingHorizontal: "$4.5",
      }}
      scrollViewContainerProps={{
        flex: 1,
      }}
      {...props}
    />
  );
};
