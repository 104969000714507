import type { ReactNode } from "react";

import { Spinner, XStack, YBlock, YStack } from "@medbillai/ui";

export const EmptyWidgetWrapper = ({
  children,
}: {
  children: ReactNode[] | ReactNode;
  showGradient?: boolean;
}) => {
  return (
    <YStack padding="$4" width="100%" height={300}>
      <YBlock
        padding="$7"
        borderRadius="$8"
        justifyContent="center"
        alignItems="center"
        gap="$1"
        backgroundColor="transparent"
        borderWidth={0}
        animation="bouncyTight"
        enterStyle={{
          opacity: 0,
          y: 10,
          scale: 0.9,
        }}
        exitStyle={{
          opacity: 0,
          y: -10,
          scale: 0.9,
        }}
        f={1}
      >
        {children}
      </YBlock>
    </YStack>
  );
};

export const EmptyLoadingWidget = () => {
  return (
    <XStack
      justifyContent="center"
      alignItems="center"
      animation="bouncyTight"
      height={300}
      f={1}
      enterStyle={{
        opacity: 0,
        y: 10,
        scale: 0.9,
      }}
      exitStyle={{
        opacity: 0,
        y: -10,
        scale: 0.9,
      }}
    >
      <Spinner />
    </XStack>
  );
};
