import { useRouter } from "solito/router";

import type { IconProps } from "@tamagui/helpers-icon";
import {
  ChevronRight,
  HelpCircle as HelpCircleIcon,
  ReceiptText as ReceiptTextIcon,
  Search as SearchIcon,
} from "@tamagui/lucide-icons";

import { IssueType } from "@medbillai/graphql-types";

import {
  Circle,
  Paragraph,
  type ParagraphProps,
  type StackProps,
  Subheadline2,
  XStack,
  YStack,
  hairline,
  isWeb,
  usePathname,
} from "@medbillai/ui";

import { Flag } from "@medbillai/utils/flag";
import { caseIntakeFlowUrl, issuePreviewUrl } from "@medbillai/utils/urls";

import { analyticsService } from "../../../lib/analytics";
import { useBoolVariation } from "../../../lib/flag";
import { WidgetHeader } from "./general-widget/WidgetHeader";

const billUrl = issuePreviewUrl(IssueType.UNKNOWN, "I need help with a bill.");
const coverageUrl = issuePreviewUrl(
  IssueType.UNKNOWN,
  "I have a question about what is covered by my health insurance.",
);
const questionUrl = issuePreviewUrl(IssueType.ONBOARDING);

export const MessageWidget = () => {
  const { push } = useRouter();
  const pathname = usePathname();

  const isCaseIntakeV1Enabled = useBoolVariation(Flag.CASE_INTAKE_V1, false);

  const handlePress = (
    type: "BILL_ISSUE" | "BENEFITS_QUESTION" | "QUESTION",
    url: string,
  ) => {
    analyticsService.trackEvent("ClickedHomeScreenChatEventButton", {
      type,
    });
    if (isCaseIntakeV1Enabled) {
      if (type === "QUESTION") {
        push(caseIntakeFlowUrl(pathname));
      } else {
        push(caseIntakeFlowUrl(pathname, type));
      }
    } else {
      push(url);
    }
  };

  return (
    <YStack
      paddingBottom="$6"
      paddingTop="$4"
      borderBottomWidth={hairline}
      backgroundColor="$background"
      borderBottomColor="$strokeSecondary"
      $platform-web={{
        paddingTop: "$10",
        borderBottomWidth: "$0",
        paddingBottom: "$0",
        backgroundColor: "transparent",
      }}
    >
      {isWeb ? (
        <Subheadline2>Welcome</Subheadline2>
      ) : (
        <WidgetHeader label="Get Started" />
      )}

      <YStack
        gap="$2.5"
        paddingHorizontal="$4"
        pt="$4"
        $platform-web={{
          flexDirection: "row",
          flexWrap: "wrap",
          paddingHorizontal: "$0",
          paddingTop: "$6",
        }}
      >
        <MessageSuggestion
          icon={ReceiptTextIcon}
          iconProps={{
            color: "$background",
          }}
          paragraphProps={{
            color: "$background",
          }}
          circleProps={{
            borderColor: "$navy5",
            backgroundColor: "$navy",
          }}
          stackProps={{
            backgroundColor: "$navy",
            hoverStyle: {
              backgroundColor: "#0a2d70",
            },
          }}
          chevronProps={{
            color: "$background",
          }}
          title="Help with a bill"
          onPress={() => handlePress("BILL_ISSUE", billUrl)}
        />
        <MessageSuggestion
          icon={SearchIcon}
          stackProps={{
            backgroundColor: "$cream",
            hoverStyle: {
              backgroundColor: "$cream5",
            },
            pressStyle: {
              backgroundColor: "$cream4",
            },
          }}
          title="Check my coverage"
          onPress={() => handlePress("BENEFITS_QUESTION", coverageUrl)}
        />
        <MessageSuggestion
          icon={HelpCircleIcon}
          stackProps={{
            backgroundColor: "$background",
            hoverStyle: {
              backgroundColor: "$cream3",
            },
            pressStyle: {
              backgroundColor: "$cream2",
            },
          }}
          title="Ask a question"
          onPress={() => handlePress("QUESTION", questionUrl)}
        />
      </YStack>
    </YStack>
  );
};

interface MessageSuggestionProps {
  icon: React.NamedExoticComponent<IconProps>;
  iconProps?: IconProps;
  circleProps?: StackProps;
  paragraphProps?: ParagraphProps;
  stackProps?: StackProps;
  chevronProps?: IconProps;
  title: string;
  onPress: () => void;
}

const MessageSuggestion = ({
  icon: Icon,
  iconProps,
  paragraphProps,
  circleProps,
  stackProps,
  chevronProps,
  title,
  onPress,
}: MessageSuggestionProps) => {
  return (
    <XStack
      br="$10"
      height="$6"
      px="$4"
      alignItems="center"
      justifyContent="space-between"
      borderWidth={hairline}
      borderColor="$strokeSecondary"
      onPress={onPress}
      pressStyle={{
        scale: 0.95,
      }}
      flexShrink={1}
      animation="200ms"
      $platform-web={{
        paddingRight: "$6",
      }}
      $sm={{ fg: 1 }}
      $gtSm={{ fg: 0 }} // or any fixed value you want
      cursor="pointer"
      {...stackProps}
    >
      <XStack alignItems="center" gap="$4">
        <Circle
          size={34}
          borderWidth={hairline}
          borderColor="$strokeSecondary"
          backgroundColor="$cream"
          {...circleProps}
        >
          <Icon size={18} color="$color" {...iconProps} />
        </Circle>
        <Paragraph bold suppressHighlighting {...paragraphProps}>
          {title}
        </Paragraph>
      </XStack>
      <ChevronRight
        size="$1"
        color="$color"
        $platform-web={{
          display: "none",
        }}
        {...chevronProps}
      />
    </XStack>
  );
};
