import { DollarSign as DollarSignIcon } from "@tamagui/lucide-icons";

import { Circle, LoadingButton, Subheadline5, styled } from "@medbillai/ui";

import {
  EmptyLoadingWidget,
  EmptyWidgetWrapper,
} from "../general-widget/EmptyWidgetWrapper";

const StyledCircle = styled(Circle, {
  size: "$5",
  backgroundColor: "$groupedSecondary",
  zIndex: 2,
  justifyContent: "center",
  alignItems: "center",
  mb: "$3.5",

  variants: {
    whiteShadow: {
      true: {
        backgroundColor: "$background",
        shadowColor: "$shadowColor",
        shadowOffset: { height: 4, width: 0 },
        shadowRadius: 6,
        shadowOpacity: 0.12,
      },
      false: {},
    },
  } as const,
});

export const EmptyBillsList = ({
  handlePress,
  loading,
  hasInsuranceLinks,
}: {
  handlePress?: () => void;
  loading: boolean;
  hasInsuranceLinks: boolean;
}) => {
  return (
    <>
      {loading ? (
        <EmptyLoadingWidget />
      ) : (
        <EmptyWidgetWrapper>
          <StyledCircle whiteShadow={!hasInsuranceLinks}>
            <DollarSignIcon
              size={24}
              strokeWidth={2}
              color={!hasInsuranceLinks ? "$navy" : "$textSecondary"}
            />
          </StyledCircle>
          <Subheadline5 textAlign="center" maxWidth={300}>
            {!hasInsuranceLinks
              ? "Connect your insurance to view and manage your bills."
              : "Your recent bills will appear here when you have them."}
          </Subheadline5>
          {!hasInsuranceLinks ? (
            <LoadingButton
              onPress={handlePress}
              size="$3"
              width="$12"
              height="$3"
              mt="$5"
            >
              Connect
            </LoadingButton>
          ) : null}
        </EmptyWidgetWrapper>
      )}
    </>
  );
};
